import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import DivWithText from "../components/DivWithText";
import UnorderedList from "../components/UnorderedList";
import InternalLink from "../components/InternalLink";

function NoticeOfPracticeClosurePage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Practice Closure Notice`]}
                title="Practice Closure Notice"
                description="Notice of Practice Closure Effective 1/31/23"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Notice of Practice Closure</h2>
                        <DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mt-4 mb-4 bg-ap-dark-bg text-white">
                            <h4 className="text-2xl mb-2">Summary</h4>
                            <UnorderedList className="ml-6">
                                <li>Dr. Nahas is closing his clinical practice at Agile Psychiatry</li>
                                <li>The closure will be effective 1/31/23</li>
                                <li>Current patients have been notified with instructions</li>
                                <li className="ml-4">Check your email, mail, and/or patient portal accounts</li>
                                <li className="ml-4">Schedule an appointment with Dr. Nahas soon if you need care prior to his departure</li>
                                <li className="ml-4">Start making plans now for care after 1/31/23</li>
                                <li>All patients will continue be able to request medical records after 1/31/23</li>
                            </UnorderedList>
                        </DivWithText>
                        <TextParagraph className="mb-8">
                            Dr. Nahas has elected to close his clinical practice at Agile Psychiatry, effective 1/31/23.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            If you are a current patient at Agile Psychiatry, please check your email, mail, and/or patient portal
                            account for instructions regarding the closure of the clinic. If you cannot find the practice closure
                            notice that was sent to you, please send Dr. Nahas a message in the patient portal
                            or call the clinic at <a href="tel:5123376684">(512) 337-6684</a> and leave a message.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Dr. Nahas will remain the custodian of the medical records for the patients seen at Agile
                            Psychiatry. If you are a current or past patient interested in requesting your medical records,
                            please visit the <InternalLink to={"medical-records"}>Medical Records</InternalLink> page
                            now and in the future to request your medical records.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <TextDiv className="h-20"/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}


export default NoticeOfPracticeClosurePage;
